<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;微信配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
      <el-form-item label="开放平台AppId">
        <el-input v-model="form.wx_app_id"></el-input>
      </el-form-item>
      <el-form-item label="开放平台AppSecret">
        <el-input v-model="form.wx_app_secret"></el-input>
      </el-form-item>
      <el-form-item label="公众号AppId">
        <el-input v-model="form.wx_gzh_app_id"></el-input>
      </el-form-item>
      <el-form-item label="公众号AppSecret">
        <el-input v-model="form.wx_gzh_app_secret"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        wx_app_id: "",
        wx_app_secret: "",
        wx_gzh_app_id: "",
        wx_gzh_app_secret: ""
      }
    };
  },
  created() {
    this.initSms();
  },
  methods: {
    initSms() {
      getSystemConfigApi({ type: "wx" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>
